.login{
    padding:14rem 1rem;
    max-width: 675px;
    margin:  0 auto;
}

.login-form{
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    padding:  2.5rem 3.5rem;
    border-radius: 8px;
}

.dark .login-form {
    background-color: var(--main-bg-color-2);
}

.login-img{
    position: absolute;
    bottom:0;
    left:0;
    width:125px;
}


.login-form input {
    color: #34495E;
}

@media all and (max-width: 768px){
    .login-img{
        display: none;
    }
}