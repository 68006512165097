/* Editor section */

.editor-section {
    background-color: #282A2E;
    min-height: calc(100vh - 5rem);
    height:100%;
    
}
.editor-bar {
    color: #fff;
    background-color: #37383C;
    border-bottom: 1px solid #707070;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;
}

.editor-bar .tabs {
    display: flex;
    flex-direction: row;
}

.editor-bar .tabs div, .editor-bar .run-btn {
    padding: 0.5rem 1rem;
    cursor: pointer;
    
}

.editor-bar .tabs div {
    transition: background-color 0.1s ease;
    position: relative;
}

.editor-bar .tabs div.selected {
    background-color: #282A2E;

}
.editor-bar .tabs div.selected::after {
    content: "";
    width: 100%;
    height:1px;
    position: absolute;
    bottom: -1px;
    left:0;
    right:0;
    background-color: #282A2E;
}


.SplitPane.editor-content.vertical {
    height:initial!important;
} 

/* Right section */
.input-output-section {
    box-sizing: border-box;
    background-color: var(--main-bg-color);

}

/* Output section */
.rendered-output-section {
    border:4px solid var(--secondary-color);
    border-radius: 4px;
    width:100%;
    box-sizing: border-box;
    min-height: 16rem;
    max-height: 50vh;
    overflow: scroll;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

#output-iframe {
    background-color: #fafafa;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/* Input section */

.input-section {
    padding:1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
}

.btn-group {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    border:2px solid var(--secondary-color);
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
}
.btn-group .btn {
    border-radius: 0;
    cursor:pointer;
    box-sizing: border-box;
    border:none;
    background-color: transparent;
    height:100%;
    padding:0.5rem 1.2rem;
    font-size:0.8rem;
}
.dark .btn-group .btn {
    color: #f1f4fed5;
}
.btn-group .btn.selected {
    background-color: var(--secondary-color);
    color:#fff;
}
.dark .input-section .input-field label {
    color: #f1f4fed5;
}

.dark .input-section .input-field input, .input-section .input-field.type2 textarea {
    color: #3b4a5a;
}

.delete-snippet{
    background-color:var(--error-color);
    color: #fff;
    width: 125px;
    text-align: center;
    margin-top: 10px;
}


/* scroll bar */



.ace_scrollbar::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #282A2E;
}


.ace_scrollbar::-webkit-scrollbar {
    width: 8px;
}

.ace_scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #777;
}




/* Resizer */

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
  
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .Resizer.horizontal {
    height: 16px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  
  .Resizer.vertical {
    width: 14px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }





@media all and (max-width: 767px) {
    .editor-content {
        display: flex;
        flex-direction: column-reverse!important;
        height: unset!important;
    }
    .Pane.Pane1 {
        width: unset!important;
    }
    .input-output-section {
        display: flex;
        flex-direction: column-reverse;
    }
}