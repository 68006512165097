.footer-bg{
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-bg .white-txt-secondary {
    color: #f1f4fed5;
}

.footer-block{
    width: 250px;
    padding: 1rem 2rem;
}

.footer-title{
    font-size: 1.25rem;
    color: #fafafa;
    font-weight: 600;
}

.footer-block a:hover {
    text-decoration: underline;
}