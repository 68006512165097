.home-header{
    background-size: cover;
    background-repeat: no-repeat;
}
.header-txt{
  width:530px;
}
.header-img{
  width: 600px;
  margin-top:2rem;
}

.home-header .white-txt {
  color:#fafafa;
}
.home-header .white-txt-secondary {
  color:#f1f4fed5;
}

@media all and (max-width:1200px){
  .header-txt{
    text-align: center;
  }
}

@media all and (max-width:768px) {
  .header-img{
    width: 375px;
    padding-top: 3rem;
  }  
}