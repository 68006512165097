.testimonial-msg{
    max-width:525px;
    margin: 2.5rem auto;
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    z-index: 10;
}

path.star{
    border: 1px solid red;
    filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.2));
}

.position-triangle{
    margin: -70px auto 0px auto;
    width: 50px;
}

.triangle{
    width: 0;
    height: 0;
    margin-left:100px;
    position: relative;
    z-index: 5;
    border-left: 1px solid transparent;
    border-right: 50px solid transparent;
    border-top: 100px solid #3a7e7b0a;
    transform: rotate(30deg);
}

.overlap-triangle{
    width: 0;
    height: 0;
    margin-left:100px;
    margin-top: -103px;
    position: relative;
    z-index: 15;
    border-left: 1px solid transparent;
    border-right: 50px solid transparent;
    border-top: 100px solid #fff;
    transform: rotate(30deg);
}

.profile-bg{
    background-color: #fff;
    width:100px;
    height: 100px;
    margin:-1rem auto 0px auto;
    border-radius: 100vh;
}

.profile-img{
    width:90px;
    height:90px;
    margin:5px auto;
    border-radius: 100vh;
    object-fit: cover;
}


.black-txt .testimonial-msg {
    color: #34495E;
}