.snippet-card{
    width: 320px;
    height: 260px;
    margin: 0px 1.5rem 2rem 1.5rem;
    border-radius: 8px;
    background-color: #fff;
    color: var(--primary-color);
    border: 3px solid transparent;
    cursor: pointer;
    transition:0.2s;
    position: relative;
}

.snippet-card-edit{
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: var(--secondary-color);
    padding: 8px 9px 3px 9px;
    border-radius: 100vh;
}

.dark .snippet-card {
    background-color: var(--main-bg-color-2);
  }

.snippet-card:hover{
    border: 3px solid var(--secondary-color);
    transition: 0.2s;
}

.snippet-title{
    font-size: 1.25rem;
    text-align: center;
    margin: 0.75rem 0px 0.5rem 0px;
    font-weight: 600;
    height: 2rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.snippet-description{
    padding:0rem 1rem;
    opacity: 0.7;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 6rem;
}

.category-group{
    padding:0.5rem 1rem;
    margin-top:1.5rem;
}