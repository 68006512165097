.search-content{
    background-color: #fff;
    border-radius: 7px;
    margin: 1rem 0px 5rem 0px;
    padding: 2rem 1rem;
}

.search-snippet {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
    border:none;
    max-width: 400px;
    width: 70vw;
    
  }

  .search-snippet:active, .search-snippet:focus {
    outline: none;
}

.search-icon{
  position: absolute;
  right: 13px;
  top: 15px;
  padding:0.75rem 0.75rem 0.75rem 0px;
}

.category-img{
  width: 35px;
  padding:3px;
  margin-top: 7px;
  border-radius: 7px;
  box-shadow: 2px 2px 15px #3a7e7b4a;
  cursor: pointer;
}

.category-box{
  position: absolute;
  background-color: #fff;
  padding: 1rem;
  border-radius: 7px;
  width: 200px;
  text-align: center;
  z-index: 40;
}

.category-title{
  color: var(--primary-color);
  font-weight: 500;
}

.category-border-line{
  margin: 0.75rem;
  border: 1px solid rgba(0,0,0, 0.2) ;
}

.dark .search-content {
  background-color: var(--main-bg-color-2);
}

@media all and (max-width:875px) {
  .category-box{
    transform: translateX(-50%);
  }
}

@media all and (max-width:563px) {
  .category-box{
    left: 50%;
  }
}