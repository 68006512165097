.confirm{
    padding:12rem 1rem;
}

.confirm-form{
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    padding:  2.5rem 3.5rem;
    border-radius: 8px;
}

.confirm-title{
    text-align: center;
  font-size: 1.75rem;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1rem;
  color: var(--primary-color)
}