@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* Color Variables */
:root {
  --main-bg-color: #fafafa;
  --main-bg-color-2: #f1f4fed5;
  --primary-color: #34495E;
  --secondary-color:#41B883;
  --purple-color: #9997FF;
  --orange-color: #F8C024;
  --light-blue-color:#87BCC7;
  --error-color: #cc0000;
}

html.dark:root {
  --main-bg-color: #25323e;
  --main-bg-color-2: #3b4a5a;
  --primary-color:#fafafa;
  --error-color: #ef5a5a;
}
/* HTML Elements */

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  font-family: 'Poppins', sans-serif;
  /* scroll-behavior: smooth; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: inherit;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Roboto', sans-serif;
  margin: 0px;
}

h1{
  font-size: 4.21rem;
}
h2{
  font-size: 3.16rem;
}
h3{
  font-size: 2.37rem;
}
h4{
  font-size: 1.78rem;
}
h5{
  font-size: 1.33rem;
}
h6{
  font-size: 1rem;
}


/*  */

.content{
  max-width: 1200px;
  margin: 0 auto;
  padding:1rem;
}

/* Flex Stuff */

.row{
  display: flex;
  flex-wrap: wrap;
}

.row-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.row-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.row-space-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.stretch{
  align-items: stretch;
}

.align-start{
  align-items: flex-start;
}
.align-center{
  align-items: center;
}
/* Buttons */

.btn{
  padding: 8px 25px;
  display: inline-block;
  font-weight: 600;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.15s ease;
  cursor: pointer;
  border:none;
}

/* .btn:hover{
  opacity: 0.9;
} */

.sign-out{
  color: var(--error-color);
  border: 2px solid var(--error-color);
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.sign-out:hover{
  color: #fff;
  background-color: var(--error-color);
}

.green-btn{
  color: #fafafa;
  background-color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.15s ease;

}
.green-btn:hover {
  opacity: 1;
  background-color: #46c78d;
}

.green-btn-outline{
  transition: background-color 0.15s ease, color 0.15s ease;
  border: 2px solid var(--secondary-color);
  box-sizing: border-box;
  padding: 6px 25px;
  background-color: transparent;
}

.dark .green-btn-outline {
  color: #fafafa;
}
.green-btn-outline:hover {
  background-color: var(--secondary-color);
  color: #fafafa;
}

.form-btn{
  padding: 8px 45px;
  display: inline-block;
  position: relative;
  left: 50%; 
  transform: translateX(-50%);
  font-weight: 600;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border: none; 
  cursor: pointer;
}

.form-btn:hover{
  opacity: 0.9;
}

.form-icon{
  position:absolute;
  top: 1.2rem;
  left: 0.6rem;
}



/* Spacing */
.m-1{
  margin: 1rem;
}

.m-2{
  margin: 2rem;
}

.m-r-1{
  margin-right: 1rem;
}

.m-lr-1{
  margin: 0px 1rem;
}

.m-t-1{
  margin-top:  1rem;
}

.m-t-2{
  margin-top: 2rem;
}

.m-t-3{
  margin-top: 3rem;
}

.m-t-5{
  margin-top: 5rem;
}
.m-b-1{
  margin-bottom: 1rem;
}
.m-b-2{
  margin-bottom: 2rem;
}

.m-b-3{
  margin-bottom: 3rem;
}

.m-tb-3{
  margin: 3rem 0px;
}

.m-tb-5{
  margin: 5rem 0px;
}

.m-t-5{
  margin-top: 5rem;
}

.m-b-8{
  margin-bottom: 8rem;
}

.m-b-10{
  margin-bottom: 10rem;
}


.p-b-3{
  padding-bottom: 3;
}

.p-tb-3{
  padding: 3rem 0px;
}

.p-tb-6{
  padding: 6rem 0px;
}

.p-t-8{
  padding-top: 8rem;
}

.p-b-12{
  padding-bottom: 12rem;
}

.p-lr-2{
  padding: 0px 2rem;
}

/* Positions */
.relative{
  position: relative;
}


/* Font-Weight */
.semi-bold{
  font-weight: 600;
}

.bold{
  font-weight: 700;
}

/* Backgrounds */
.white-bg{
  background-color: var(--main-bg-color);
}

.extra-white-bg{
  background-color: #fff;
}

/* Text */

.white-txt{
 color: var(--main-bg-color); 
}
.white-txt-secondary {
  color: var(--main-bg-color-2);
}
.black-txt{
  color: var(--primary-color);
}

.green-txt{
  color: var(--secondary-color);
}

.txt-center{
  text-align: center;
}

.txt-left{
  text-align: left;
}

.error-txt{
  color: var(--error-color);
  font-weight: 500;
  font-size: 0.75rem;
}

.success-txt{
  color: var(--secondary-color);
  font-weight: 500;
  font-size: 0.75rem;
}

.error-txt ul {
  padding-left: 1rem;
  margin: 0;
}

.form-title{
  text-align: center;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: var(--primary-color)
}

/* Shadow */
.light-shadow{
  box-shadow: 2px 2px 15px #3a7e7b22;
  /* box-shadow: 2px 2px 2px rgba(0,0,0, 0.2) ; */
}

.text-shadow{
  text-shadow:2px 2px rgba(0,0,0, 0.2) ;
}


/* CSS Testing */
.border-test{
  border: 1px solid red;
}

/* categories */
.selected-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.selected-category-tag {
  border-radius: 100vh;
  background-color: var(--secondary-color);
  color:#fff;
  padding:6px 12px;
  font-size: 0.8rem;
  margin: 0 0.5rem 0.5rem 0;
}

.selected-category-tag span {
  cursor: pointer;
  padding-left:0.25rem;
}


/* Form/Inputs */

.input-field.type2 label {
  text-transform: uppercase;
  font-size: .9rem;
}
.input-field.type2 input, .input-field.type2 textarea, .login-signup-input {
  background-color: #fff;
  border:none;
  border-bottom: 2px solid var(--primary-color);
  padding:0.55rem;
  width:100%;
  box-sizing: border-box;
  margin:0.5rem 0;
  transition: border-color 0.15s ease;
  font-size:0.85rem;
  color: var(--primary-color);
  font-family: 'Roboto', sans-serif;
}

.input-field.type2 input:active, .input-field.type2 textarea:active,
.input-field.type2 input:focus, .input-field.type2 textarea:focus, .login-signup-input:active, .login-signup-input:focus {
  outline:none;
  border-color: var(--secondary-color);
}

.input-field.type2 textarea {
  resize: vertical;
  min-height: 4rem;
}

.login-signup-input.error, .login-signup-input.error{
  outline:none;
  border-color: var(--error-color);
}

.login-signup-input{
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  padding-left: 2.5rem;
}


.dark .dark-secondary-text {
  color: #f1f4fed5;
}

@media all and (max-width:768px) {
  body {
    font-size: 0.82rem;
  }

}