.signup{
    padding:12rem 1rem;
    max-width: 800px;
    margin:  0 auto;
}

.signup-form{
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    padding:  2.5rem 3.5rem;
    border-radius: 8px;
}

.dark .signup-form {
    background-color: var(--main-bg-color-2);
}

.signup-img{
    position: absolute;
    bottom:0;
    right:0;
    width:175px;
}


.signup-form input {
    color: #34495E;
}
.already-member{
    margin-top: 1rem;
    text-decoration: underline;
    color: var(--secondary-color);
    font-weight: 600;
}

@media all and (max-width: 768px){
    .signup-img{
        display: none;
    }
}



