.profile-section{
    background-color: #fff;
    border-radius: 8px;
    width: 325px;
    margin: 3.75rem 1rem 1rem 1rem;
    color: var(--primary-color);
    position: relative;
    padding-bottom: 1rem;
}


.dark .profile-section {
    background-color: var(--main-bg-color-2);
}
.profile-title-section{
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: var(--primary-color);
    padding: 0rem 0px 1rem 0px;
}

.profile-title{
    font-size: 1.5rem;
    font-weight: 600;
}

.username-title{
    font-size: 1.25rem;
    font-weight: 600;
}

.plus-btn{
    background-color: var(--secondary-color);
    color: var(--main-bg-color);
    font-size: 1.75rem;
    padding: 0px 0.75rem;
    font-weight: 600;
    border-radius: 100vh;
    cursor: pointer;
    transition: 0.2s;
}

.plus-btn:hover{
    opacity: 0.9;
}

.user-img{
    width:185px;
    height:185px;
    border-radius: 100vh;
    object-fit: cover;
    margin: -3.5rem auto 1.75rem auto;
}

.linkedin-icon{
 position: absolute;
 top: 82px;
 left: 80px;
 width: 40px;
 cursor: pointer;
}

.github-icon{
    position: absolute;
    top: 100px;
 left: 138px;
 width: 50px;
 cursor: pointer;
}

.email-icon{
    position: absolute;
    top: 82px;
 right: 80px;
 width: 40px;
 cursor: pointer;
}

.snippet-scrollbar{
    height: 700px;
    overflow-y: scroll;
}

.snippet-section{
    width: 750px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    
}



.banner{
    padding: 1rem;
    border-radius: 8px 8px 0px 0px;
    width: 295px;
    margin-left: -3px;
    margin-top: -3px;
    transition:0.2s;
}

.purple-banner{
    background-color: var(--purple-color);
}

.orange-banner{
    background-color: var(--orange-color);
}

.dark-blue-banner{
    background-color: var(--primary-color);
}

.light-blue-banner{
    background-color: var(--light-blue-color);
}

.snippet-card:hover .purple-banner, .snippet-card:hover .orange-banner, .snippet-card:hover .dark-blue-banner, .snippet-card:hover .light-blue-banner{
    background-color: var(--secondary-color);
    transition:0.2s;
}

.snippet-scrollbar::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #ffffff;
    border-radius: 8px;
}


.snippet-scrollbar::-webkit-scrollbar {
    width: 7px;
}

.snippet-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(235, 235, 235);
}

@media all and (max-width: 1150px){
    .snippet-section{
        justify-content: space-around;
    }
}

@media all and (max-width:775px){
    .snippet-section{
        width: 350px;
    }
}