.nav{
    position: fixed;
    z-index: 50;
    top:0;
    left:0;
    width:100%;
    transition: 0.3s;
    
}

.nav-links {
    display: flex;
    align-items: center;
    
}

.nav-logo {
    width: 40px;
    height: 40px;
}

.nav-profile-img{
    width:47px;
    margin: 1rem 1.25rem;
    border-radius: 100vh;
}

.nav-title{
    font-size: 2rem;
    color: var(--secondary-color)
}

.nav-group{
display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.nav-burger{
    display: none;
}



.nav.black-txt .nav-links a {
    color: var(--primary-color);
}

.nav-search-snippet {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
    border:none;
    width: 300px;
  }

  .nav-search-snippet:active, .nav-search-snippet:focus {
      outline: none;
  }
  
  .nav-search-icon{
    position: absolute;
    right: 13px;
    top: 15px;
    padding:0.75rem 0.75rem 0.75rem 0px;
  }

  .search-line{
      margin: -5px 0.6rem;
      border: 1px solid rgba(0,0,0,0.1);
  }

  .nav .sign-out {
    padding: 6px 18px;
    font-size: 0.9rem;
  }

  .nav.white-txt .sign-out:hover {
    background-color:#fff;
    color:var(--error-color);
  }

  /* Edit nav bar styles */

.edit-nav {
    height:5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.edit-nav .nav-group {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.edit-nav button {
    margin: 0 0.5rem;
}
.edit-nav .nav-group .right {
    display: flex;
    align-items: center;
}

.dark .nav.white-txt svg {
    color:#fff;
}

.dark .edit-nav {
    color: #f4f4f4;
}
@media all and (min-width:768px) {
    .nav.white-txt .sign-out {
        color:#fff;
        border-color: #fff;
    }
    .nav.white-txt .nav-links a {
        color: #fff;
    }
    .nav.black-txt .nav-links .login-btn {
        color: #fafafa;
    }
    .nav-screen {
        display:none;
    }
}
@media all and (max-width:768px) {
    .nav-screen {
        display:block;
        position: fixed;
        z-index:5;
        left:0;
        right:0;
        top:5rem;
        bottom:0;
        /* background-color: red; */
    }
    .edit-nav button {
        margin: 0 0.25rem;
        padding-left:0.9rem; 
        padding-right:0.9rem; 
    }
    .nav-burger{
        display: block;
    }
    .nav-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 5rem;
        width:100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: var(--main-bg-color);
    }



    .nav-links .link{
        width:100%;
        background-color: var(--main-bg-color);
        box-shadow: none;
        text-shadow: none;
        color: var(--primary-color);
        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
        padding:20px;
        text-align: center;
    }

    .dark .nav-links, .dark .nav-links .link {
        background-color: var(--main-bg-color-2);
    }
    .dark .nav-links .link {
        color: var(--primary-color);
    }

    .nav-links a:hover{
        background-color: #f4f4f4;
    }
    .nav-hidden {
        display: none;
    }
    .desktop {
        display: none;
    }
    .nav-burger {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .nav-burger svg {
        margin-right: 10px;
    }

    .dark .nav-burger {
        color: var(--primary-color); 
    }

    .nav .sign-out {
        border:none;
    }
    .nav .profile-link {
        width: 100%;
        justify-content: center;
        display: flex;
        padding:0;
    }
    
}

